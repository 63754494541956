import { ViewerControllerProps } from '../../types/viewer';
import {
  IAppData,
  IControllerConfig,
} from '@wix/native-components-infra/dist/src/types/types';
import { create } from '@wix/fedops-logger';
// import { useEffect } from 'react';
// import wixRecorder from '@wix/wix-recorder';

export default ({ controllerConfig }: ViewerControllerProps) => {
  const config: IControllerConfig = controllerConfig.config;
  const appParams: IAppData = controllerConfig.appParams;

  // Here you can use wixCodeApi (corvid), API reference is here:
  // https://bo.wix.com/wix-docs/client/viewer-platform---ooi/reference/wixcode-apis
  // And Wix Selector ($w) to manipulate with elements on the page, API reference is here:
  // https://www.wix.com/corvid/reference/$w
  // controllerConfig has more useful data, for the details look inside types and here:
  // https://bo.wix.com/wix-docs/client/viewer-platform---ooi/articles/lifecycle
  const { $w, wixCodeApi, compId, platformAPIs } = controllerConfig;
  const viewMode = wixCodeApi?.window?.viewMode ?? 'Preview';
  const formFactor = wixCodeApi?.window?.formFactor ?? 'Desktop';
  const deviceType = wixCodeApi.window.formFactor ?? 'Desktop';
  // useEffect(() => {
  //   if(['HOTEL_RUNNER_APP_ID'].includes(appParams.appDefinitionId)) {
  //     wixRecorder.addLabel(appParams.appDefinitionId);
  //   }
  // })
  const fedopsLogger = create('web-component-wrapper-logs');
  const widgetLogger = platformAPIs.fedOpsLoggerFactory?.getLoggerForWidget({
    appId: appParams.appDefinitionId,
    widgetId: compId,
  });

  return {
    pageReady: async () => {
      if ($w('@webComponent').length !== 0) {
        $w('@webComponent').on('startPayment', async (event: any) => {
          fedopsLogger.interactionStarted('new-wcw-payments');
          const interactionName = 'wcw-startpayment';
          widgetLogger?.interactionStarted(interactionName);
          // @ts-ignore
          const result = await wixCodeApi.pay.startPayment(
            event.detail.orderId,
            {
              showThankYouPage: false,
              userInfo: event.detail.userInfo,
              skipUserInfoPage: true,
            }
          );
          // @ts-ignore
          wixCodeApi.window.trackEvent(`${event.compId}|payments`, {
            detail: result,
          });
          fedopsLogger.interactionEnded('new-wcw-payments');
          widgetLogger?.interactionEnded(interactionName);
        });
        const { pages } = await wixCodeApi.site.getSiteStructure({
          includePageId: true,
        });
        const stringified = JSON.stringify({
          viewMode,
          instanceId: appParams.instanceId,
          compId,
          deviceType,
          pages,
          authorization:
            wixCodeApi?.site?.getAppToken &&
            wixCodeApi?.site?.getAppToken(appParams.appDefinitionId),
          formFactor,
        });
        $w('@webComponent').setAttribute('wixconfig', stringified);
        $w('@webComponent').on('navigateTo', (event1: any) => {
          const { page } = event1.detail;

          if (wixCodeApi.location.to) {
            let path = `/${page}`;
            if (page === 'home') {
              path = '/';
            }
            wixCodeApi.location.to(path);
          }
        });
        $w('@webComponent').on('web-component-event', (event2: any) => {});
      }
    },
  };
};
